.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  min-width: 100%;
}

.footer {
  margin-top: auto;
}
